import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../components/Layout';
import { Section } from '../../components/Section';
import { Calculator, CalculatorAside } from '../../components/Calculator';
import Testimonials from '../../components/Testimonials';
import SectionTop from '../../components/SectionTop';
import staticData from '../index-data.json';
import './index.scss';

const CollegeEssays = () => (
  <Layout className="IndexPage CollegeEssays">
    <Helmet>
      <title>College Essay Writing Assistance According to Your Demands | Ca.CustomWritings.com</title>
      <meta
        name="description"
        content="Order college essay examples written by professional academic writers at reasonable prices. We’re here to help you with any academic writing - don’t waste time!"
      />
      <link rel="canonical" href="https://ca.customwritings.com/college-essays.html" />
      <link rel="alternate" hrefLang="en" href="https://www.customwritings.com/college-essay.html" />
      <link rel="alternate" hrefLang="en-ca" href="https://ca.customwritings.com/college-essays.html" />
      <link rel="alternate" hrefLang="x-default" href="https://www.customwritings.com/college-essay.html" />
    </Helmet>

    <SectionTop>
      <h1 className="Section-top__content__title">Searching for affordable college essays?</h1>
      <span className="Section-top__content__subtitle" />
      <span className="Section-top__content__description">Our experts are ready to help you in more than 70 disciplines and with any type of academic task your college assigns you</span>
      <a rel="nofollow" href="https://www.customwritings.com/dashboard/orders/create/" className="button">Buy an essay</a>
    </SectionTop>

    <Section className="Section Section-seo">
      <h2 className="Section__heading Section__heading--bubble-1">Get essay examples for college by experts writers</h2>
      <p>
        Essay writing causes one no end of grief throughout one’s education, but once you get into college, it really
        starts to become painful. College essay writing traditionally constitutes a huge portion of your workload, and
        how well you do it heavily influences your grades. For the students who don’t have a natural inclination for
        this kind of work, every college essay is yet another kick in the stomach that guarantees they’ll have neither
        time nor energy to pick themselves up and put their affairs in order. One way to alleviate this situation is
        to find a trustworthy academic assistance service writing high-quality college essay examples you can later
        use as a foundation for your own work.
      </p>
      <p>
        However, things aren’t that straightforward for students of Canadian colleges. The thing is, you use sample
        essays in college to learn or better understand the way you’re supposed to write this or that type of academic
        assignment, and with their help, you improve your mastery of formatting styles, writing conventions, and other
        things you have to know to deal with any high-level writing assignments. Unfortunately, the majority of services producing college essay samples specialize in writing for American students, and writing conventions in the USA have their own peculiarities that are sometimes at odds with the way things are done in Canada. As a result, if you need a college essay example, you had better make sure the service you order it from is familiar with the specifics of Canadian essay writing and formatting.
      </p>
    </Section>

    <Section className="Section-calculator Section--bg-blue">
      <h2 className="Section__heading">Calculate the price of your paper here</h2>
      <div className="calculator-wrapper">
        <Calculator />
        <CalculatorAside />
      </div>
    </Section>

    <Section className="Section Section-seo">
      <h2 className="Section__heading Section__heading--bubble-2">Get an essay sample for college from a service used to Canadian students</h2>
      <p>
        CustomWritings.com is a college essay service specializing in helping students strictly according to their
        requirements and following the norms specific to their colleges and universities. All you have to do is visit
        our website, fill in the order form or contact our customer support, and say, “Write my college essay,
        please”. We’ll immediately start looking for a suitable writer among our employees, and eventually assign the
        specialist with the best set of skills and characteristics to deal with this particular job. In addition, we
        promise:
      </p>
      <ul>
        <li>
          Complete confidentiality and security. When you order an essay sample for college from us, the details of
          the job and the very fact we had any transactions remain between you and our service. Our cybersecurity
          measures are among the best in the industry.
        </li>
        <li>
          Absence of plagiarism. Every college essay sample we sell is 100 percent original. We check everything for
          originality using our in-house anti-plagiarism software and encourage you to recheck the results using any
          tools of your choice.
        </li>
        <li>
          Low prices. Although writing a college essay is usually a fairly expensive job, we do our best to perfect
          our workflow and save costs wherever possible. This way, we can offer our clients some of the lowest prices
          in the industry—our fees start at a mere $10 per page, and only extremely urgent tasks cost significantly
          more.
        </li>
        <li>
          Universality. In addition to writing, we also offer a college essay editing service. If you take a look at
          our order page, you will see a variety of additional options you can use to customize your order. Larger
          assignments can even be broken down into multiple parts, to be paid for and delivered individually.
        </li>
      </ul>
    </Section>

    <Section className="Section Section-testimonials Section--bg-blue">
      <h2 className="Section__heading">What customers say about us</h2>
      <Testimonials testimonials={staticData.testimonials} />
    </Section>

    <Section className="Section Section-seo">
      <h2 className="Section__heading Section__heading--bubble-3">Affordable college essay writing for discerning customers</h2>
      <p>
        We understand that for our clients, a lot depends on how well we do our job. Writing essay for college
        students is a huge responsibility—a poorly written paper can mean no end of trouble for a student who decides
        to use it. This is why we’re extremely careful about what kind of writers we hire. Before becoming an employee
        at CustomWritings.com, an applicant has to pass a number of tests to show that he/she is fully capable of
        doing the work and upholding our high-quality standards. For the safety and peace of mind of our clients, we
        also offer them a variety of guarantees, including:
      </p>
      <ul>
        <li>
          Free revisions. If you find that your essay doesn’t fully meet your requirements, you have 7 days after
          receiving it to ask for any number of free revisions. As long as you don’t contradict your original
          instructions, we’ll be happy to oblige.
        </li>
        <li>
          Money-back. If the order is late, the paper is poorly written, or one of a variety of other unfortunate
          situations occurs, you have the right to ask for a full or partial refund (you can find out about it in more
          detail in our money-back policy).
        </li>
        <li>
          Round-the-clock support. You can contact us at any time of night or day—somebody will be online to answer
          your questions.
        </li>
      </ul>
      <p>
        CustomWritings.com does everything in its power to make the life of our clients easier and more comfortable.
        Place an order right now, and see for yourself!
      </p>
    </Section>
  </Layout>
);

export default CollegeEssays;
